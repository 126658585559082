import { spdcost_http_get, spdcost_http_post, spdcost_http_put } from './helper';

export interface SpanRemarks {
    additionalStirrup: string;
    hangingRebar: string;
    leftSupportName: string;
    limbCount: number;
    modelElementId: string;
    rightSupportName: string;
    stirrupStyle: string;
    topRebar: string;
    id: string;
    spanNumber: number;
    leftSupportRebar: string;
    rightSupportRebar: string;
    erectionbar: string;
    bottomRebar: string;
    sideRebar: string;
    stirrup: string;
    leftSupportLocation: number;
    rightSupportLocation: number;
    leftSupportElementId: string;
    rightSupportElementId: string;
    leftSupportHC: number;
    rightSupportHC: number;
}
export interface RebarValidators {
    fieldName: string;
    validation: string;
    description: string;
  }

 /** 绘制楼层梁跨标注 */
export async function getFloorBeamRemarks(params: {buildingId: string; floorNumber: number}) {
    const res = await spdcost_http_get('/Quantity/BeamReinforcement/FloorBeamRemarks', params);
    return res as {elementId: number; spanRemarks: SpanRemarks}[];
}
 /** 起止跨对调 */
 export async function postBeamSpanReverse(params: { buildingId: string; modelElementId: string }) {
    const res = await spdcost_http_post('/Quantity/BeamReinforcement/BeamSpanReverse', params);
    return res as SpanRemarks[];
}
 /** 获取梁跨配筋验证规则 */
 export async function getRebarValidators() {
    const res = await spdcost_http_get('/Quantity/BeamReinforcement/RebarValidators', {}, true);
    return res as RebarValidators[];
}

/** 删除梁钢筋标注 */
export async function deleteBeamRebar(params: { buildingId: string; modelElementIds: string[] }) {
    const res = await spdcost_http_put('/Quantity/BeamReinforcement/BeamRemarks', params);
    return res as { success: boolean; code: number; message: string; data: [] };
}
