// eslint-disable-next-line max-classes-per-file
export interface PropertyPattern {
    label: string;
    validation: string;
    hint: string;
    isSupport: true;
    test(input: string): boolean;
    getReg(): RegExp;
}

export interface PropertyValidator {
    qsType: number;
    keyword: string;
    rebarLabels: PropertyPattern[];
    test(input: string): boolean;
    getRegs(): RegExp[];
}

class PropertyPatternImpl implements PropertyPattern {
    public label: string;

    public validation: string;

    public hint: string;

    public isSupport: true;

    private reg: RegExp | undefined;

    public constructor(pattern: PropertyPattern) {
        this.label = pattern.label;
        this.validation = pattern.validation;
        this.hint = pattern.hint;
        this.isSupport = pattern.isSupport;
    }

    public test(input: string): boolean {
        if (!input || !this.validation) return true;

        if (!this.reg) {
            this.reg = new RegExp(this.validation);
        }

        return this.reg.test(input);
    }

    public getReg(): RegExp {
        if (!this.reg) {
            this.reg = new RegExp(this.validation);
        }

        return this.reg;
    }
}

export class PropertyValidatorImpl implements PropertyValidator {
    public qsType: number;

    public keyword: string;

    public rebarLabels: PropertyPattern[];

    public constructor(validator: PropertyValidator) {
        this.qsType = validator.qsType;
        this.keyword = validator.keyword;
        this.rebarLabels = validator.rebarLabels?.map((label) => new PropertyPatternImpl(label)) ?? [];
    }

    public test(input: string): boolean {
        return this.rebarLabels.some((pattern) => pattern.test(input));
    }

    public getRegs(): RegExp[] {
        return this.rebarLabels.map((propPattern) => propPattern.getReg());
    }
}
