import Vue from 'vue';
import ElementUI from 'element-ui';
import BigPicture from './plugins/bigPicture';
import App from './App.vue';
import Moment from './plugins/moment';
import router from './router';
import store from './store';
import './filters';
import '@/assets/style/element-variables.scss';
import 'swiper/css/swiper.css';
import 'animate.css';
import 'babel-polyfill';
import Print from './plugins/print';
import api from './plugins/api';

Vue.use(api);
Vue.use(ElementUI);
Vue.use(Print);
Vue.use(Moment);
Vue.use(BigPicture);

// todo: 这样封装覆盖了 this.$message.success() 接口，应该有更好的封装方式
// Vue.prototype.$message = function $message(msg) {
//     msg.duration = 2000;
//     ElementUI.Message(msg);
// };

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
