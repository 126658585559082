import { spdcost_http_get } from './helper';
import { FloorList, FloorQstypeOptions } from './types';

// todo: 获取楼层树，但实际上楼层不是一棵树啊，只是一个数组
interface GetFloorTreeRes {
    nameId: string;
    name: string;
    nodes: GetFloorTreeRes[];
}

export async function getFloorTree(params: { buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/Floor/Tree', params);
    return res as GetFloorTreeRes;
}

// 获取构件楼层
export async function putElementFloors(params: {
    buildingId: number;
    elementIds: number[];
}) {
    const res = await spdcost_http_get('/Quantity/BillCodeMatch/ElementFloors', params);
    return res as {
        floorId: number;
        number: number;
        name: string;
    }[];
}

export async function getFloor(params: { buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/Floor', params);
    return res as FloorList[];
}

// 获取单个楼层列表(分解合并了的)
export async function getFloorOptions(params: { buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/Floor/Options', params);
    return res as FloorList[];
}

// 获取可复制楼层构件
export async function getFloorQstypeOptions(params: { buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/Floor/QSTypeOptions', params);
    return res as FloorQstypeOptions[];
}
