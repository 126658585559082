import Vue from 'vue';
import BigPictureVue from './BigPicture.vue';

const BigPictureConstructor = Vue.extend(BigPictureVue);
const instance: any = new BigPictureConstructor({
    el: document.createElement('div'),
});
document.body.appendChild(instance.$el);

const BigPicture: any = {};
BigPicture.install = function (vue) {
    const setImg: any = (img) => instance.setImg(img);
    vue.prototype.$bigPicture = setImg;
};

export default BigPicture;
