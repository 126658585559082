import {
 spdcost_http_delete, spdcost_http_get, spdcost_http_post, spdcost_http_put,
} from './helper';
import { BillTreeNode, BillMatchRow, BillMatchFilter } from './types';
// 删除匹配的清单
export async function deleteBillMatch(params: { elementIds: string[]; billCode: string; buildingId: string }) {
    const res = await spdcost_http_delete('/Quantity/BillCodeMatch', params);
    return res;
}

// 添加清单匹配
export async function addBillMatch(params: { elementIds: string[]; billCode: string; buildingId: string }) {
    const res = await spdcost_http_put('/Quantity/BillCodeMatch', {
        modelElementId: params.elementIds,
        billCode: params.billCode,
        buildingId: params.buildingId,
    });
    return res;
}
// 批量替换清单
export async function batchReplaceMatch(params: { elementIds: string[]; bills: [] }) {
    const res = await spdcost_http_put('/Quantity/BillCodeMatch/BatchReplace', params);
    return res;
}
// 批量添加清单
export async function batchAddMatch(params: { elementIds: string[]; bills: [] }) {
    const res = await spdcost_http_post('/Quantity/BillCodeMatch/BatchAdd', params);
    return res;
}
export async function getBillMatchPage(params: BillMatchFilter) {
    const res = await spdcost_http_get('/Quantity/BillCodeMatch/Page', {
        BuildingId: params.buildingId,
        FloorNumber: params.floorNumber,
        ParentQSType: params.qsCategoryVal,
        QSType: params.qsTypeVal,
        ElementName: params.elementName,
        IsOnlyUnmatchedElement: params.isOnlyUnmatchedElement,
        PageIndex: params.pageIndex,
        PageSize: params.pageSize,
    });
    return res as {
        totalElementCount: string;
        unmatchedElementCount: string;
        matchTaskStatus: number;
        total: string;
        rows: BillMatchRow[];
    };
}

// 获取清单树
export async function getAllBillLibs(params: { libId: string }) {
    const res = await spdcost_http_get('/Quantity/BillCode/Tree', params, true);
    return res as BillTreeNode[];
}

/**
 * 修改清单名称
 * @param billCode 清单编码
 */
export async function updateBillName(params: { buildingId: string; name: string; billCode: string; elementIds: string[] }) {
    const res = await spdcost_http_put('/Quantity/BillCodeMatch/BillName', params);
    return res;
}

/**
 * 修改清单单位
 * @param billCode 清单编码
 */
export async function updateBillUnit(params: { buildingId: string; unit: string; billCode: string; elementIds: string[] }) {
    const res = await spdcost_http_put('/Quantity/BillCodeMatch/SwitchUnit', params);
    return res;
}

/** 重新匹配 */
export async function reMatch(params: { buildingId: string; clearPolicy: number }) {
    const res = await spdcost_http_put('/Quantity/BillCodeMatch/Match', params);
    return res;
}
