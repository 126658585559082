const initProperty = sessionStorage.getItem('property');
const property = initProperty ? (JSON.parse(initProperty)) : [{ groupName: '' }];
const AppModule = {
    state: {
        byId: {},
        ids: [],
        currentId: null,
        isExpired: false, // 账号是否过期
        isCollapse: true, // 左侧菜单状态：true收起/false展开 app view
        property, // 构件属性展开关闭操作 app view
        userGuidance: { // 用户导引索引 app
            idx: -1,
        },
        guidanceProjectFirstId: null, // 导引项目id app
        defaultTheme: true, // 模型管理默认主题色
    },
    getters: {
        appVersion(state) {
            return state.byId[state.currentId] && state.byId[state.currentId].appVersion;
        },
        orgName(state) {
            return state.byId[state.currentId] && state.byId[state.currentId].name;
        },
        orgId(state) {
            return state.byId[state.currentId] && state.byId[state.currentId].id;
        },
        isExpired(state) {
            return state.byId[state.currentId] && state.byId[state.currentId].isExpired;
        },
    },
    mutations: {
        setAppList(state, appList) {
            state.byId = {};
            state.ids = [];
            appList.forEach((app) => {
                state.byId[app.id] = app;
                state.ids.push(app.id);
            });
            // 当前 app 默认选择第一个
            if (!state.currentId) {
                state.currentId = appList[0].id;
            }
        },
        setAppId(state, id) {
            state.currentId = id;
        },
        guidanceProjectFirstId(state, guidanceProjectFirstId) {
            state.guidanceProjectFirstId = guidanceProjectFirstId;
        },
        userGuidance(state, userGuidance) {
            state.userGuidance = userGuidance;
        },
        property(state, val) {
            const idx = state.property.findIndex((e) => e.groupName === val.groupName);
            if (idx > -1) {
                state.property[idx] = val;
            } else {
                state.property.push(val);
            }
            sessionStorage.setItem('property', JSON.stringify(state.property));
        },
        isCollapse(state, isCollapse) {
            state.isCollapse = isCollapse;
        },
        setDefaultTheme(state) {
            state.defaultTheme = !state.defaultTheme;
        },
    },
    actions: {
        async changeApp({ commit }, id) {
            commit('setAppId', id);
        },
    },
};

export default AppModule;
