import Vue from 'vue';
import Vuex from 'vuex';
import operand from './modules/operand';
import user from './modules/user';
import project from './modules/project';
import modelModule from './modules/modelModule';
import buildingModule from './modules/buildingModule';
import modelSitulabel from './modules/modelSitulabel';
import app from './modules/app';
import loading from './modules/loading';

Vue.use(Vuex);

export default new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',
    modules: {
        operand,
        user,
        project,
        modelModule,
        app,
        buildingModule,
        modelSitulabel,
        loading,
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
});
