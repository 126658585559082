
  import Vue from 'vue';
  import guidanceSteps from './guidance';
  import GuidanceFlowPath from './GuidanceFlowPath.vue';
  import GuidanceAloneStep from './GuidanceAloneStep.vue';
  import GuidanceModelManagement from './GuidanceModelManagement.vue';
  import GuidanceAddModel from './GuidanceAddModel.vue';
  import GuidanceModelComponent from './GuidanceModelComponent.vue';
  import GuidanceCalculationConfig from './GuidanceCalculationConfig.vue';
  import GuidanceFloorSetting from './GuidanceFloorSetting.vue';
  import GuidanceQuantities from './GuidanceQuantities.vue';

  export default Vue.extend({
    data() {
      return {
        pages: [
          { name: '' },
          { name: GuidanceFlowPath },
          { name: GuidanceAloneStep },
          { name: GuidanceModelManagement },
          { name: GuidanceAddModel },
          { name: GuidanceModelComponent },
          { name: GuidanceCalculationConfig },
          { name: GuidanceFloorSetting },
          { name: GuidanceAloneStep },
          { name: GuidanceQuantities },
        ],
        guidanceSteps,
        content: '' as string | string[],
        title: '',
        details: [] as { name: string; content: string }[],
        arrow: '',
        xiaozhi: '',
        current: -1,
        visible: false,
        over: false,
      };
    },
    computed: {
      userGuidance(): any {
        return this.$store.state.app.userGuidance;
      },
      projectId(): any {
        return this.$store.state.app.guidanceProjectFirstId;
      },
      userName(): string {
        return this.$store.state.user.information.name;
      },
    },
    watch: {
      userGuidance() {
        this.assignment();
      },
      userName(val) { // 登录后需要判断是否使用过导引
        if (!val) return;
        this.getUserGuidesIsRead();
      },
    },
    // mounted() {
    //   this.assignment(); // 测试用
    // },
    methods: {
      async getUserGuidesIsRead() {
        const res = await this.$api.UserGuides.getUserGuidesIsRead();
        if (res === false) {
          this.$store.commit('userGuidance', { idx: 0 });
        }
      },
      async postUserGuidReads() { // 已经看过导引
        const data = { module: '主流程' };
        await this.$api.UserGuides.postUserGuidReads(data);
      },
      assignment() { // 赋值
        if (this.userGuidance.idx === -1) return;
        this.current = this.userGuidance.idx;
        const val = guidanceSteps[this.current];
        this.content = val.content;
        this.title = val.title || '';
        this.details = val.data ? val.data : [];
        this.arrow = val.arrow;
        this.xiaozhi = val.xiaozhi;
        this.visible = true;
      },
      next() { // 下一步
        this.visible = false;
        const val = guidanceSteps[this.current + 1];
        if (guidanceSteps[this.current].url !== val.url) {
          let path: any = val.url;
          if (this.current === 6) {
            path = {
              path,
              name: '算量设置',
              value: 'calculation-config',
            };
          }
        if (this.current === 0) {
          this.$router.push({
            path: '/project/building/model-manage',
            query: {
              project_id: this.projectId,
            },
          });
        } else {
            this.$router.push({ path });
          }
        }
        this.current += 1;
        this.$store.commit('userGuidance', { idx: this.current });
      },
      intoMonomer() {
        sessionStorage.setItem('page', this.$route.query.page as string);
        sessionStorage.setItem('size', this.$route.query.size as string);
      },
      help() {
        const homeOrigin = window.location.origin.replace('service.spdcost', 'www.spdcost');
        window.open(`${homeOrigin}/guide`, '_blank');
      },
      kit() {
        window.open('http://www.spdkit.com/');
      },
      close() {
        this.visible = false;
        this.$store.commit('userGuidance', { idx: -1 });
        if (this.$route.query.guidance) {
          this.$router.push({ query: {} });
        } else {
          this.postUserGuidReads();
        }
      },
    },
  });
