import { getAllElementDetails } from '@/api/floorSet';

const benchList: Array<any> = [];

export const modelState = {
    allComList: {} as {
        floorNumbers: number[];
        modelElementIds: string[];
        qsCategories: number[];
        qsTypes: number[];
        totalCount: number;
        viewElementIds: string[];
    },
    benchList, // 构建模型的进程，当渲染进程超过2个时，释放资源 model
    selectedElements: [], // 选中的构件 id ['HTg6YjGmzECLbbqEKphoNgEA']
    selectedModelElementIds: [], // ['289221466610729189']
    visibleElements: [], // 显隐显示的构件 id
    elementByViewId: {}, // 通过 id 查找构件
    elementByModelId: {}, // 通过 id 查找构件
    modelLoadComplete: false, // 模型加载状态 model
    fullScreen: false, // 模型是否全屏显示 model
    home: false, // 模型工具条home model
    openModelSitulabel: false, // 打开原位标注
    isShowHelpList: false,
};

const modelModule = {
    namespaced: true,
    state: modelState,
    mutations: {
        isShowHelpList(state, val) {
            state.isShowHelpList = val;
        },
        benchList(state, val) {
            state.benchList = [val];
            sessionStorage.setItem('bench', val);
        },
        clearElementSelection(state) {
            state.selectedElements = [];
        },
        setSelectedElements(state, ids) {
            if (state.selectedElements.toString() === ids.toString()) return;
            state.selectedElements = ids;

            if (ids.length === 0) {
                state.selectedModelElementIds = [];
                return;
            }
            const selectedModelElementIds = [] as string[];
            ids.forEach((id) => {
                const index = state.allComList.viewElementIds.findIndex((e) => e === id);
                if (index > -1)selectedModelElementIds.push(state.allComList.modelElementIds[index]);
            });
            state.selectedModelElementIds = selectedModelElementIds;
        },
        setAllComList(state, val) {
            state.allComList = val;
        },
        modelLoadComplete(state, modelLoadComplete) {
            state.modelLoadComplete = modelLoadComplete;
        },
        home(state, home) {
            state.home = home;
        },
        fullScreen(state, fullScreen) {
            state.fullScreen = fullScreen;
        },
        openModelSitulabel(state) {
            state.openModelSitulabel = false;
            setTimeout(() => {
                state.openModelSitulabel = true;
            }, 20);
        },
    },
    actions: {
        // 获取所有构件列表
        async getAllElementDetails({ commit, rootState }) {
            const res = await getAllElementDetails({ buildingId: rootState.buildingModule.currentBuildingInfo.buildingId });
            commit('setAllComList', res);
        },
    },
};

export default modelModule;
