import axios from 'axios';
import Vue from 'vue';
import { login } from '@/api/UserAccount';

const { CancelToken } = axios;
Vue.prototype.$httpRequestList = [];

const service = axios.create({
    baseURL: window.location.origin,
    headers: {
        'Content-Type': 'application/json',
    },
});
service.interceptors.request.use((config) => {
    if (config.url.indexOf('Quantity/RebarDefinition/GetRebarDefinitions') > -1) { // 图形配筋的接口防止重复获取数据
        config.cancelToken = new CancelToken((cancel) => {
            Vue.prototype.$httpRequestList.push(cancel);
        });
    }
    const accessToken = sessionStorage.getItem('access_token');
    // 对参数加密可以封装在这里
    if (accessToken) {
        config.headers.Authorization = accessToken;
    }
    if (config.body) {
        config.data = JSON.stringify(config.body);
    } else if (config.ContentType === 'multipart/form-data') {
        if (config.files === config.data.FileName) {
            config.source && (config.cancelToken = config.source.token);
        } else {
            config.source && (config.cancelToken = null);
        }
        const formData = new FormData();
        Object.keys(config.data).forEach((key) => {
            formData.append(key, config.data[key]);
        });
        config.data = formData;
    }
    return config;
}, (err) => {
    Promise.reject(err);
});

service.interceptors.response.use((response) => {
    if (response.status !== 200) {
        return Promise.reject(new Error(response.message || 'Error'));
    }
    return response.data;
}, (error) => { // todo: 这部分代码需要优化
    console.error(error);
    if (axios.isCancel(error)) {
        // 为了终结promise链 就是实际请求 不会走到.catch(rej=>{});这样就不会触发错误提示之类了。
        // return new Promise((resolve, reject) => {});
    }
    if (error.response.status === 401) {
        // ASSERT: 暂定方案是 embed 模式下有刷新 token 机制，所以永远不会走到这一步
        if (!sessionStorage.getItem('embed')) {
            login();
        }
    }
    if (error.response.status === 400) {
        return error.response.data;
    }
    if (error.response.status === 500) {
        console.error('服务器端错误');
    }
    return Promise.reject(error.response);
});

export default service;
