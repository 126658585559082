import http from '../utils/axiosAsync';
import { API } from '../constants/index';
import {
    spdcost_http_get, spdcost_http_delete, spdcost_http_put, spdcost_http_post,
} from './helper';
import * as type from './types';
import { BuildingDetail } from './types';

/**
 * 获取产品列表
 */
export async function findProjects(opts: type.ProjectQueryParams) {
    const res = await spdcost_http_get('/Project/Projects/ProjectBriefPage', opts);
    res.total = parseInt(res.total, 10);
    res.rows.forEach((item) => {
        if (item.images.length !== 0) {
            item.image = `/spdcost/${item.images[0]}`;
        } else {
            item.image = null;
        }
    });
    return res as { rows: type.ProjectInfo[]; total: number };
}

/**
 * 获取当前 Tab 页下所有项目的分类
 */
export async function findCategoriesByTab(tab: 0 | 1 | 2) {
    const res = await spdcost_http_get('/Project/Projects/GetOrgBuildingCategories', { tab });
    return res as type.ProjectCategory[];
}

/**
 * 获取当前 Tab 页下所有项目的城市
 */
export async function findAddressesByTab(tab: 0 | 1 | 2) {
    const res = await spdcost_http_get('/Project/Projects/GetOrgProjectAddresses', { tab });
    return res as any;
}

/**
 * 删除项目
 */
export async function deleteProject(id: string) {
    await spdcost_http_delete('/Project/Projects/{id}', { id });
}

/**
 * 切换项目状态: 归档、激活
 */
export async function updateProductState(id: string) {
    await spdcost_http_put('/Project/Projects/{id}/Status', { id });
}

/**
 * 获取项目详细信息
 */
export async function getProjectDetail(id: string) {
    const res = await spdcost_http_get('/Project/Projects/ProjectDetail', { id });
    res.readonly = !(res.permissions & 5);
    if (res.address === null) {
        res.address = {
            province: '',
            city: '',
        };
    }
    if (res.projectCategory === null) {
        res.projectCategory = {
            name: '',
            secondaryName: '',
        };
    }
    return res as type.ProjectDetail;
}

/**
 * 获取工程列表
 */
export async function getAllBuildings() {
    const res = await spdcost_http_get('/Project/BuildingTier', {});
    return res.roots as BuildingDetail[];
}

/**
 * 判断项目名是否重复
 * @param id 项目id，编辑项目的时候防止自己和自己重复导致误判
 * @param name 项目名
 * @throws 错误信息
 */
export const isNameExists = async (id: string, name: string) => {
    await spdcost_http_put('/Project/Projects/IsExistsProjectNameAndCode', {
        projectField: 'name',
        value: name,
        id,
    });
};

/**
 * 判断项目编号是否重复
 * @param id 项目id，编辑项目的时候防止自己和自己重复导致误判
 * @param code 项目编号
 * @throws 错误信息
 */
export const isCodeExists = async (id: string, code: string) => {
    await spdcost_http_put('/Project/Projects/IsExistsProjectNameAndCode', {
        id,
        projectField: 'code',
        value: code,
    });
};

/**
 * 新建项目
 */
export async function addProject(params: any) {
    const res = await spdcost_http_post('/Project/Projects', params);
    return res as {
        id: string;
    };
}

/**
 * 获取一级分类
 */
export async function getMainType() {
    const res = await spdcost_http_get('/Project/BuildingCategories/GetPrimary', {}, true, false);
    return res as {
        id: string;
        code: string;
        name: string;
        secondaryName: string;
        tertiaryName: string;
    }[];
}

/**
 * 获取二级分类
 */
export async function getSecType(params: { name: string }) {
    const res = await spdcost_http_get('/Project/BuildingCategories/GetSecondary/{name}', params);
    return res as {
        id: string;
        code: string;
        name: string;
        secondaryName: string;
        tertiaryName: string;
    }[];
}

// 保存工程
export async function updateBuilding(params: any) {
    await spdcost_http_put('/Project/Building', params);
}

// 删除工程
export async function deleteBuilding(params: { id: string }) {
    await spdcost_http_delete('/Project/Building/{id}', params);
}

export async function addBuilding(params: any) {
    await spdcost_http_post('/Project/Building', params);
}

export async function editorProject(params: any) {
    await spdcost_http_put(`/Project/Projects/${params.id}`, params);
}

export function upload() {
    http({
        url: API.upload,
        method: 'post',
    });
}

/**
 * 创建/编辑项目信息的时候用到的清单库
 */
export async function getAllBills(cacheFlag = false) {
    const res = await spdcost_http_get('/Quantity/BillCode/Libs', {}, cacheFlag);
    return res as { libId: string; name: string }[];
}

/**
 * 获取平法规则
 */
export async function getProjectFlat(cacheFlag = false) {
    const res = await spdcost_http_get('/Project/Projects/GetFlatAtlass', {}, cacheFlag);
    return res as {
        id: string;
        name: string;
        description: string;
    }[];
}

/**
 * 模型管理列表
 */
export async function getBuildingModelList(id: string) {
    const res = await spdcost_http_get('/Project/BuildingModel/GetModelList', {
        buildingId: id,
    });
    return res as any[];
}

// 模型管理上传
export async function postBuildingModel(params: any) {
    await spdcost_http_post('/Project/BuildingModel', params);
}

// 修改主模型
export async function postBuildingModelChangeMasterState(params: {
    isMaster: boolean;
    fileId: string;
    buildingId: string;
}) {
    await spdcost_http_post('/Project/BuildingModel/ChangeMasterState', params);
}

// 删除模型
// fixme: swagger上参数是放在query里面的
export async function deleteModel(opts: { buildingId: string; modelId?: string; fileId: string }) {
    await spdcost_http_delete('/Project/BuildingModel', opts);
}

// 替换模型
export async function putBuildingModel(params: {
    buildingId: string;
    filePath: string;
    isMaster: boolean;
    modelId: number;
    size: number;
    viewFileId: string;
    fileName: string;
}) {
    await spdcost_http_put('/Project/BuildingModel/Replace', params);
}

// 重命名工程
export async function updateBuildingName(params: { id: string; name: string }) {
    await spdcost_http_put('/Project/Building/Rename', params);
}

// 发送计算任务
export async function sendCalculateTask(params: { buildingId: string }) {
    await spdcost_http_post('/Project/BuildingModel/SendExtractMessage', params);
}

// 获取土建设置
export async function getCivilSetting(params: { buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/CivilSetting', params);
    return res as {
        civilSettings: any;
        wallThickDefinitions: any[];
    };
}

export async function putCivilSetting(params: {
    inCivilSetting: {
        id: string;
        value: string;
    }[];
    buildingId: string;
}) {
    await spdcost_http_put('/Quantity/CivilSetting', params);
}

// 保存土建墙厚设置
export async function putWallThickSave(params: {
    inWallThickDefinition: {
        id: string;
        defineValue: number;
        actualValue: number;
        sequence: number;
    }[];
    buildingId: string;
}) {
    await spdcost_http_put('/Quantity/CivilSetting/WallThickSave', params);
}

// 获取构件属性
export const getProperties = (params) => http({
    url: API.getProperties,
    params,
    method: 'get',
});

// 获取构件属性详情
export async function postPropertiesDefinitions(params: {
    buildingId: string;
    qsCategory: string | null;
    qsType: string | null;
    qsSubType: string | null;
    elementIds: string[];
}) {
    const res = await spdcost_http_post('/Quantity/Element/PropertiesDefinitions', params);
    return res as {
        isSameType: boolean;
        allProperties: any[];
        groups: any[];
    };
}

// 修改构件属性
export async function putElement(params: {
    buildingId: string;
    elementIds: string[];
    properties: {
        keyword: string;
        value: string;
    }[];
}) {
    await spdcost_http_put('/Quantity/Element/Properties', params);
}

// 获取构件类型分组列表
export async function putElementTree(params: any) {
    const res = await spdcost_http_put('/Quantity/Element/Tree', params);
    return res as any[];
}

// 获取专业范围
export async function getCalcSelection() {
    const res = await spdcost_http_get('/Quantity/CalcSelection/Majors', {});
    return res as any;
}

// 获取选中值
export async function getQSCalcSelection(buildingId: string) {
    const res = await spdcost_http_get('/Quantity/CalcSelection', { buildingId });
    return res as any;
}

// 保存算量范围
export async function putCalcSelection(params: {
    qsMajorScope: string[];
    floors: number[];
    qsTypes: string[];
    isOnlyTable: boolean;
    elements?: string[];
    buildingId: string;
}) {
    await spdcost_http_put('/Quantity/CalcSelection', params);
}

// 我的报表
export async function getReportMine() {
    const res = await spdcost_http_get('/Quantity/Report/Mine', {});
    return res as any[];
}

// 工程信息汇总
export async function getReportBuilding(opts: { reportId: string | number; buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/Report/Building', opts);
    return res as {
        buildingName: string;
        buildingCategory: string;
        structTypeTxt: string;
        overgroundFloorCount: number;
        undergroundFloorCount: number;
        floorArea: number;
        antiEarthQuackTypeTxt: string;
        environmentTypeTxt: string;
        physicalRebarWeightSum: number;
        measureRebarWeightSum: number;
        rebarWeightPerSquare: number;
        designCompany: string;
        ownerCompany: string;
        constructionCompany: string;
        supervisorCompany: string;
    };
}

// 报表范围
export async function getRebarUsageCategory() {
    const res = await spdcost_http_get('/Quantity/Report/RebarUsageCategory', {});
    return res as {
        code: string;
        val: number;
        txt: string;
        isLeaf: boolean;
        nodes: null[]; // todo
        options: null[]; // todo
    }[];
}

// 土建报表
export async function putReportCivil(params: {
    reportId: string;
    buildingId: string;
    floorNames: string[];
    reportScopes: number[];
    qsTypes: number[];
}) {
    const res = await spdcost_http_put('/Quantity/Report/Civil', params);
    // todo
    return res as {
        headers: any[];
        rows: any[];
    };
}

// 字典枚举
export const getDict = (params) => http({
    url: API.getDict,
    params,
    method: 'get',
});

// 反查模型
export async function getReportElements(opts: any) {
    const res = spdcost_http_get('/Quantity/Report/Elements', opts);
    return res as any;
}

// 获取工程信息
export async function getBuildingMessage(params: { id: string }) {
    const res = await spdcost_http_get('/Project/Building/{id}', params);
    return res as {
        id: string;
        name: string;
        structType: number;
        overgroundArea: number;
        undergroundArea: number;
        heightDiffer: number;
        antiKnockGrade: number;
        environmentType: number;
        defaultAntiKnockGrade: number;
        defaultEnvironmentType: number;
        qsTaskInQueueCount: number;
        isFaulted: boolean;
        unreadCalcDoneMessageId?;
    };
}

// 获取工程信息
export async function getViewInfo(params: { buildingId: number; modelId: number }) {
    const res = await spdcost_http_get('/Project/BuildingModel/ViewInfo', params);
    return res as {
        status: number;
        modelFileViewId: string;
        files: any[];
        relation: any;
        percent: number;
        isRedirected: boolean;
    };
}

// 获取错误类型
export async function getErrorType(buildingId: string) {
    const res = await spdcost_http_get('/Quantity/QSError/Type', { buildingId });
    return res as any[];
}

// 获取错误分组
export async function getErrorGroup(opts: { buildingId: string; entity: string }) {
    const res = await spdcost_http_get('/Quantity/QSError/Group', opts);
    return res as { name: string; count: number }[];
}

// 获取设置错误
export async function getErrorSetting(opts: any) {
    const res = await spdcost_http_get('/Quantity/QSError/Setting', opts);
    return res as any;
}

// 获取清单错误
export async function getErrorBill(opts: any) {
    const res = await spdcost_http_get('/Quantity/QSError/Bill', opts);
    return res as any;
}

// 获取属性错误
export async function getErrorProperty(opts: any) {
    const res = await spdcost_http_get('/Quantity/QSError/Property', opts);
    return res as any;
}

// 获取模型错误
export async function getErrorModel(opts: any) {
    const res = await spdcost_http_get('/Quantity/QSError/Model', opts);
    return res as any;
}

// 上传模型缩略图
export async function UpLoadThumbnail(params: { id: string; imageUrl: string }) {
    await spdcost_http_put('/Project/BuildingModel/UpLoadThumbnail', params);
}

// 获取工程算量版本
export async function getBuildingVersion(params: { buildingId: number }) {
    const res = await spdcost_http_get('/Quantity/QSVersion/Building/{buildingId}', params);
    return res as {
        id: string;
        name: string;
    }[];
}

// 获取项目选项
export async function getOptions(opts: any) {
    const res = await spdcost_http_get('/Project/Projects/Options', opts);
    return res as any;
}

// 获取对比
export async function getCriterion(opts: any) {
    const res = await spdcost_http_get('/Analysis/Criterion', opts);
    return res as {
        platform: number;
        user: number;
        current: number;
    };
}

// 获取明细对比
export async function getCriterionDetails(opts: any) {
    const res = await spdcost_http_get('/Analysis/Criterion/Details', opts);
    return res as {
        categoryTxt: string;
        platform: number;
        user: number;
        current: number;
    }[];
}

// 获取工程总量
export async function getBuildingQuantityDetails(opts: { buildingId: string; quantityType: number }) {
    const res = await spdcost_http_get('/Analysis/BuildingQuantityDetails', opts);
    return res as any[];
}

// 获取指标分析中的工程
export async function getBuildingCriterion({ projectId }) {
    const res = await spdcost_http_get('/Project/Building/Criterion', { projectId });
    return res as any;
}

// 获取查询结果
export async function getExist(opts: any) {
    const res = spdcost_http_get('/Analysis/Criterion/Projects/Exist', opts);
    return res as any;
}

// 获取单个截面构件信息
export async function getElementGeometries(params: {
    buildingId: string;
    modelElementIds: string[];
    instersectElementCategories: number[];
    // includeRebar?: boolean;
    // includeInstersectElements: boolean;
}) {
    const res = await spdcost_http_put('/Quantity/RebarDefinition/ElementGeometries', params);
    return res as any;
}
// 获取单个截面钢筋信息
export async function getRebarDefinitions(params: {
    buildingId: string;
    modelElementIds: string[];
    instersectElementCategories: number[];
}) {
    const res = await spdcost_http_put('/Quantity/RebarDefinition/RebarDefinitions', params);
    return res as any;
}
// 获取楼层截面配筋信息
export async function GetFloorRebarDefinitions(params: {
    buildingId: string;
    floorNumber: number;
    includeElementCategories: number[] | undefined;
    // includeRebarCategories: number[] | undefined;
    // includeProperties: boolean | undefined;
}) {
    const res = await spdcost_http_put('/Quantity/RebarDefinition/FloorRebarDefinitions', params);
    return res as any;
}
// 获取楼层截面构件信息
export async function GetFloorElementGeometries(params: {
    buildingId: string;
    floorNumber: number;
    includeElementCategories: number[] | undefined;
}) {
    const res = await spdcost_http_put('/Quantity/RebarDefinition/FloorElementGeometries', params);
    return res as any;
}
// 更新截面配筋信息
export async function putSaveRebarDefinitions(params: any) {
    await spdcost_http_put('/Quantity/RebarDefinition/SaveRebarDefinitions', params);
}

// 修改纵筋根数
export async function getDefaultRebarDefinitions(params: {
    buildingId: string;
    modelElementIds: string[];
    instersectElementCategories: [];
    // includeRebar?: boolean;
    // includeInstersectElements: boolean;
}) {
    const res = await spdcost_http_put('/Quantity/RebarDefinition/DefaultRebarDefinitions', params);
    return res as {
        elementRebars: any[];
        elementInfos: any[];
    };
}

// 获取构件基础信息
export async function getElementBasic(params: {
    buildingId: string;
    elementId: string;
}) {
    const res = await spdcost_http_get('/Quantity/ElementCheck/Basic', params);
    return res as {
        elementName: string;
        errors: {
            message: string;
        }[];
      };
}

// 获取属性增量(修改某个下拉属性值时)
export async function putPropertyIncrements(params: {
    buildingId: string;
    modelElementIds: string[];
    allProperties: string[];
    exists: {
        keyword: string;
        value: string;
    }[];
    changingProperty: string;
}) {
    const res = await spdcost_http_put('/Quantity/Element/PropertyIncrements', params);
    return res as {
        additionalProp1: any;
        additionalProp2: any;
        additionalProp3: any;
    };
}

/**
 * 判断是否能上传模型
 */
export async function modelCheck(opts: { buildingId: string; fileSize: number }) {
    await spdcost_http_get('/Project/BuildingModel/Check', opts);
}
