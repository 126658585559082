import { spdcost_http_get, spdcost_http_put } from './helper';

export async function getUserGuidesIsRead() {
    const res = await spdcost_http_get('/Foundation/UserGuides/IsReaded', {});
    return res as boolean;
}

export async function postUserGuidReads(params: { module: string}) {
    await spdcost_http_put('/Foundation/UserGuides', params);
}
