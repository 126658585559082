
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                left: 0,
            };
        },
        mounted() {
            if (this.$store.state.app.userGuidance.idx === 2) {
                this.left = (document.querySelector('.selectDropdown') as any).offsetLeft;
            }
        },
    });
