import { getUserInfo, getUserInfoSSO } from '@/api/UserInfo';

const UserModule = {
    state: {
        picture: '', // 头像需要从重庆的接口拿
        information: {
            // 用户基本信息
            name: '',
            username: '',
            email: '',
            phone: '',
            // 容量空间使用情况
            maxBuildingCount: 0,
            buildingCount: 0,
            maxProjectCount: 0,
            projectCount: 0,
            maxModelCount: 0,
            modelCount: 0,
            maxSpaceUsage: 0,
            spaceUsage: 0,
            maxTaskCount: 0,
            taskCount: 0,
        },
        userName: '',
    },
    mutations: {
        setUserSpdcostInfo(state, info) {
            state.information.name = info.name;
            state.information.username = info.userName;
            state.information.phone = info.phone;
            state.information.email = info.email;
            state.information.maxBuildingCount = Number(info.maxBuildingCount);
            state.information.buildingCount = Number(info.buildingCount);
            state.information.maxProjectCount = Number(info.maxProjectCount);
            state.information.projectCount = Number(info.projectCount);
            state.information.maxModelCount = Number(info.maxModelCount);
            state.information.modelCount = Number(info.modelCount);
            state.information.maxSpaceUsage = Number(info.maxSpaceUsage);
            state.information.spaceUsage = Number(info.spaceUsage);
            state.information.maxTaskCount = Number(info.maxTaskCount);
            state.information.taskCount = Number(info.taskCount);
        },
        setUserName(state, name) {
            state.information.name = name;
        },
        setUserPicture(state, picture) {
            state.picture = picture;
            // if (picture && picture.includes('/spdbim')) {
            //     state.picture = picture.split('/spdbim')[0].concat(picture.split('/spdbim')[1]);
            // } else {
            //     state.picture = picture;
            // }
        },
    },
    actions: {
        async fetchUserInformation({ commit }) {
            const informationRes = await getUserInfo();
            commit('setUserSpdcostInfo', informationRes);
            const infoRes = await getUserInfoSSO();
            commit('setUserPicture', infoRes.picture);
        },
        async fetchUserPicture({ commit }) {
            const infoRes = await getUserInfoSSO();
            commit('setUserPicture', infoRes.picture);
        },
        async changeApp({ dispatch }) {
            dispatch('fetchUserInformation');
        },
    },
};

export default UserModule;
