import {
    getfloorartlist,
    postfloorartlist,
    postfloorartcopy,
    putfloorartxg,
    getSecondTree,
} from '@/api/floorSet';
import {
    getFloorOptions,
} from '@/api/floor';
import buildingModule from '@/store/modules/buildingModule';

const operand = {
    state: {
        currentFloor: null,
        componentsTree: [],
        currentComponent: null,
        categorySecondTree: [], // 构建类别对应的二级树
    },
    mutations: {
        setCurrentFloorId(state, floorId?: number) {
            let floor = buildingModule.state.floorList.find((f: any) => f.floorId === floorId);
            if (!floor && buildingModule.state.floorList.length > 0) {
                floor = buildingModule.state.floorList[0]; // 必须选一个楼层
            }
            state.currentFloor = floor;
        },
        setComonentsTree(state, tree) {
            state.componentsTree = tree;
        },
        setCurrentComponent(state, component) {
            state.currentComponent = component;
        },
        setCategorySecondTree(state, tree) {
            state.categorySecondTree = tree;
        },
    },
    actions: {
        async fetchFloors({ dispatch, commit, rootState }) {
            const option = {
                buildingId: rootState.buildingModule.currentBuildingInfo.buildingId,
            };
            const res = await getFloorOptions(option);
            commit('setFloorList', res);
            await dispatch('selectFloor');
        },
        async fetchCategorySecondTree({ state, commit }) {
            if (process.env.NODE_ENV !== 'production' && state.categorySecondTree.length > 0) {
                console.warn('发现重复调用fetchCategorySecondTree接口，请优化');
            }
            const option = { firstLevelEnum: 'QSCategory', secondLevelEnum: 'QSType' };
            const res = await getSecondTree(option);
            commit('setCategorySecondTree', res);
        },
        // 切换当前选中的楼层
        async selectFloor({ commit, dispatch }, floorId?: number) {
            commit('setCurrentFloorId', floorId);
            await dispatch('fetchComponents');
        },
        async fetchComponents({ state, commit }) {
            if (!state.currentFloor) return;
            const res = await getfloorartlist({
                floorNumber: state.currentFloor.number,
                qsMajorScope: 1,
            });
            const tree = res || [];
            // assert: 树深度肯定为2
            tree.forEach((element) => {
                element.id = element.qsTypeTable;
                element.isLeaf = false;
                element.elements.forEach((i) => {
                    i.isLeaf = true;
                });
            });
            commit('setComonentsTree', tree);
        },
        // 切换当前选中的构件
        async selectComponent({ commit }, component) {
            commit('setCurrentComponent', component);
        },
        async addComponent({ state, dispatch }, component) {
            const option = {
                floorId: state.currentFloor.floorId,
                name: component.name,
                qsTypeTable: component.qsTypeTable,
                qsMajorScope: 1,
                quantity: component.quantity,
            };
            await postfloorartlist(option);
            dispatch('fetchComponents');
        },
        async copyComponent({ state, dispatch }, component) {
            await postfloorartcopy({
                targetFloorIds: [state.currentFloor.floorId],
                floorModelElementIds: [component.id],
            });
            dispatch('fetchComponents');
        },
        async editComponent({ dispatch }, component) {
            const option = {
                id: component.id,
                qsTypeTable: component.qsTypeTable,
                name: component.name,
                quantity: 1,
            };
            await putfloorartxg(option);
            dispatch('fetchComponents');
        },
    },
};
export default operand;
