import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';

Vue.use(Router);

const constantRoutes: RouteConfig[] = [
    {
        path: '/',
        name: '/',
        meta: {
            requiresAuth: true,
        },
        component: () => import('./views/Index.vue'),
        redirect: '/projects',
        children: [
            {
                path: '/projects',
                name: 'ProjectList',
                component: () => import('./views/ProjectList/Index.vue'),
            },
            {
                path: '/project/create',
                name: 'ProjectCreate',
                component: () => import('./views/ProjectEditor/Index.vue'),
            },
            {
                path: '/project/edit',
                name: 'ProjectEdit',
                component: () => import('./views/ProjectEditor/Index.vue'),
            },
            {
                path: '/indicator-analysis',
                name: 'IndicatorAnalysis',
                component: () => import('./views/IndicatorAnalysis/Index.vue'),
            },
            {
                path: '/case-compare',
                name: 'CaseCompare',
                component: () => import('./views/CaseCompare/Index.vue'),
            },
            {
                path: '/personal/manage',
                name: 'PersonalManage',
                component: () => import('./views/Personal/Index.vue'),
            },
        ],
    },
    {
        path: '/project/building',
        name: 'Building',
        component: () => import('./views/Project/Index.vue'),
        redirect: '/project/building/model-manage',
        children: [
            /** 模型管理 */
            {
                path: 'model-manage',
                name: 'ModelManage',
                component: () => import('./views/Model/Index.vue'),
            },
            /** 算量设置 */
            {
                path: 'calculation-config/:type',
                name: 'CalculationConfig',
                component: () => import('./views/Setting/Index.vue'),
            },
            /** 清单匹配 */
            {
                path: 'list-match',
                name: 'list-match',
                component: () => import('./views/BillcodeMatch/Index.vue'),
            },
            /** 工程量 */
            {
                path: 'project-count',
                name: 'project-count',
                component: () => import('./views/Quantity/Index.vue'),
            },
        ],
    },
    {
        path: '*',
        name: 'page404',
        meta: {
            requiresAuth: true,
        },
        component: () => import('./views/Page404.vue'),
    },
];

const router = new Router({
    mode: 'history',
    routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
    if (typeof to.query.access_token === 'string') {
        // todo: 因为 axiosAsync.js 的遗留代码是从 session 提取 token 的，所以这边也放在 session 里面了，这个要一起改一下
        sessionStorage.setItem('access_token', to.query.access_token);
        sessionStorage.setItem('embed', JSON.stringify(true)); // 这种模式下不走重庆的身份认证体系
        sessionStorage.setItem('layout', JSON.stringify(to.query.layout));
    }
    next();
});

export default router;
