import { render, staticRenderFns } from "./GuidanceCalculationConfig.vue?vue&type=template&id=21fe455c&scoped=true&"
import script from "./GuidanceCalculationConfig.vue?vue&type=script&lang=ts&"
export * from "./GuidanceCalculationConfig.vue?vue&type=script&lang=ts&"
import style0 from "./GuidanceCalculationConfig.vue?vue&type=style&index=0&id=21fe455c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21fe455c",
  null
  
)

export default component.exports