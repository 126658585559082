export default {
    address: {
        address: '',
        city: '',
        country: '',
        district: '',
        province: '',
        fullAddress: '',
    },
    id: '',
    amountOfInvestment: '',
    billCodeLibId: '1',
    code: '',
    completeTime: '',
    constructionCompany: '',
    createTime: '',
    createUser: null,
    designCompany: '',
    flatAtlas: '1',
    floorArea: '',
    images: [],
    memberCount: 0,
    name: '',
    ownerCompany: '',
    ownerType: 1,
    permissions: 1,
    planDuration: 0,
    projectCategory: {
        code: '',
        id: '',
        name: '',
        secondaryName: '',
        tertiaryName: '',
    },
    qsTypeMapRuleLibId: '1',
    stageName: '进行中',
    startTime: '',
    supervisorCompany: '',
    fullAddress: '',
    projectCategoryId: '',
    dayNum: '',
};
