import { spdcost_http_get, spdcost_http_put } from './helper';

interface BroadcastMessage {
    id: string;
    title: string;
    content: string;
}

export async function getBroadcastMessage() {
    const res = await spdcost_http_get('/Foundation/BroadcastMessage/UnreadNotice', {});
    return res as null | BroadcastMessage;
}

export async function putReadedBroadcastMessage(params: { id: string }) {
    await spdcost_http_put('/Foundation/BroadcastMessage/Read', params);
}

// 全部已读
export async function putSiteMessage(params: { buildingId: string }) {
    await spdcost_http_put('/Foundation/SiteMessage', params);
}
