
    import Vue from 'vue';
    import Guidance from './components/guidance/Guidance.vue';

    export default Vue.extend({
        components: {
            'v-guidance': Guidance,
        },
        name: 'app',
        data() {
            return {
                showHeader: true,
            };
        },
        watch: {
            '$route.path': function () {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                if (this.$route.name === 'page404') {
                    this.showHeader = false;
                }
            },
        },
        created() {
            // if (sessionStorage.getItem('store')) {
            //     this.$store.replaceState({ ...this.$store.state, ...JSON.parse(sessionStorage.getItem('store') || '{}') });
            // }
            // window.addEventListener('beforeunload', () => {
            //     sessionStorage.setItem('store', JSON.stringify(this.$store.state));
            // });
        },
    });
