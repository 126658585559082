const guidanceSteps = [
    {
        url: '/projects',
        arrow: 'left',
        xiaozhi: '',
        title: '欢迎使用SPDCOST，我是蜘蛛小智。',
        content: '为了您更快更好的使用本产品，将由我为你简单讲解下功能操作，您也可以随时点击关闭结束引导。',
    },
    {
        url: '/Project/building/model-manage',
        arrow: '',
        xiaozhi: 'left',
        content: '',
        data: [
            { name: '模型管理', content: '管理模型文件，编辑构件信息' },
            { name: '算量设置', content: '完善工程概况，修改算量设置' },
            { name: '清单匹配', content: '核查匹配结果，修改构件清单' },
            { name: '开始算量', content: '设置计算范围，开始工程计算' },
            { name: '工程量', content: '查看工程报表，核查计算结果' },
        ],
    },
    {
        url: '/Project/building/model-manage',
        arrow: 'left',
        xiaozhi: 'right',
        content: '在此展开可新建单项工程或编辑已有工程，一个项目下可添加多个工程。',
    },
    {
        url: '/Project/building/model-manage',
        arrow: 'left',
        xiaozhi: 'right',
        content: '此处可针对模型进行增删改。',
    },
    {
        url: '/Project/building/model-manage',
        arrow: 'left',
        xiaozhi: 'right',
        content: [
            '模型采用',
            'SPDKIT',
            '工具核查，扣减，使模型符合算量标准，上传后算量更精准哦。',
        ],
    },
    {
        url: '/Project/building/model-manage',
        arrow: 'right',
        xiaozhi: 'left',
        content: '请使用构件功能，完善属性及钢筋信息，使算量结果更准确。',
    },
    {
        url: '/Project/building/calculation-config/set-base',
        arrow: 'left',
        xiaozhi: 'right',
        content: '通过自由调整不同设置，可提高算量精准度。',
    },
    {
        url: '/Project/building/calculation-config/set-base',
        arrow: 'left',
        xiaozhi: 'right',
        content: ['楼层设置', '可自动从模型中获取楼层信息，按楼层设置抗震等级、混凝土强度等级、保护层厚度等。'],
    },
    {
        url: '/Project/building/project-count',
        arrow: 'right',
        xiaozhi: 'left',
        content: '点击开始算量，可进行模型算量和表格算量。',
    },
    {
        url: '/Project/building/project-count',
        arrow: 'left',
        xiaozhi: 'right',
        content: [
            '计算完成后，您可在工程量内查看详细报表数据，双击数据可反查模型。',
            '本次引导到此结束，如有更多问题，可点击右上方',
            '帮助文档',
            '查看，感谢您的阅览。',
        ],
    },
];

export default guidanceSteps;
