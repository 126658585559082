import http from '../utils/axiosAsync';
import { API } from '../constants/index';
import { spdcost_http_put } from './helper';
// 查询文件上传的状态
export const uploadFileStatus = (params) => http({
        url: API.uploadFileStatus,
        method: 'get',
        params,
        spider: 1,
    });

// 在fileServer中添加上传成功的文件
export const addFileServer = (data) => http({
        url: API.addFileServer,
        method: 'put',
        data,
        spider: 1,
    });

export const uploadImg = (data) => http({
        ContentType: 'multipart/form-data',
        url: API.addFileServer,
        method: 'post',
        data,
        spider: 1,
    });

// 大文件上传
export const UploadModels = (data, source, files) => http({
        url: API.UploadModel,
        method: 'post',
        data,
        source,
        files,
        ContentType: 'multipart/form-data',
        spider: 1,
    });

// 图片上传postUploadPicture
export const postUploadPicture = (data) => {
    const res = http({
    url: window.location.origin + API.addFileServer,
    method: 'post',
    data,
    ContentType: 'multipart/form-data',
    });

    if (!res.success) {
        throw Error(res.message);
    }

    return res.items as any[];
};

// 模型转换
export const convertModelBySelf = (params) => http({
        url: API.convertModelBySelf,
        method: 'get',
        params,
        spider: 1,
    });

// 查询模型转换的进度
export const convertProcessBySelf = (params) => http({
        url: API.convertProcessBySelf,
        method: 'get',
        params,
        spider: 1,
    });

// 查询相同modelGroupId下面的模型列表
export const getModelList = (params) => http({
        url: API.getModelList,
        method: 'get',
        params,
        spider: 1,
    });

// 加载模型
export const modelRelation = (params) => http({
        url: API.modelRelation,
        method: 'get',
        params,
        spider: 1,
    });

// 加载模型
export const deleteModel = (params) => http({
        url: API.deleteModel,
        method: 'delete',
        params,
        spider: 1,
    });

// 获取单个模型信息
export const getFileMes = (params) => http({
        url: API.getFileMes + params.id,
        method: 'get',
        spider: 1,
    });

// 获取轻量化引擎的构件Id
export async function putViewElementIds(ids: string[]) {
    const res = await spdcost_http_put('/Quantity/Element/ViewElementIds', { elementIds: ids });
    return res as string[];
}

export async function uploadPicture(data: { file: any }) {
    const res = await http({
        url: '/spdcost/api/v1.3/Common/File/Image',
        method: 'post',
        data,
        ContentType: 'multipart/form-data',
    });
    if (!res.success) {
        throw Error(res.message);
    }
    return res.data as string;
}
