import projectData from './projectData';

const projectModule = {
    namespaced: true,
    state: {
        currentProjectInfo: null as any, // 当前项目信息
        projectData: sessionStorage.getItem('projectData') ? JSON.parse(sessionStorage.getItem('projectData') as any) : projectData,
        currentProjectEditable: true,
        nodeName: '',
    },
    getters: {
        currentProjectId(state) {
            return state.currentProjectInfo.id;
        },
        currentProjectName(state) {
            return state.currentProjectInfo.name;
        },
    },
    mutations: {
        setNodeName(state, name) {
            state.nodeName = name;
        },
        editProject(state, data) {
            if (data === 'create') {
                state.projectData = projectData;
                sessionStorage.setItem('projectData', JSON.stringify(projectData));
            } else {
                data.flatAtlas = data.flatAtlas.toString();
                state.projectData = data;
                sessionStorage.setItem('projectData', JSON.stringify(data));
            }
        },
        updateCurrentProject(state, data) {
            state.currentProjectInfo = data;
        },
        clearCurrentProject(state) {
            state.currentProjectInfo = {};
        },
    },
};

export default projectModule;
