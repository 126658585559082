import {
    getAllBuildings,
} from '@/api/project';
import {
    getFloorOptions,
  } from '@/api/floor';

const sessionBuildingInfo = sessionStorage.getItem('buildingInfo');
const currentBuildingInfo = sessionBuildingInfo ? (JSON.parse(sessionBuildingInfo)) : {};
const progress = sessionStorage.getItem('progress');
const sessionPropertiesHistory = sessionStorage.getItem('propertiesHistory');
const propertiesHistory = sessionPropertiesHistory ? (JSON.parse(sessionPropertiesHistory)) : {};

const buildingModule = {
    state: {
        buildingList: [], // 模型列表
        currentBuildingInfo, // 当前工程信息
        progress, // 算量进度 building
        progressSuccess: false, // building
        countError: false, // 计算错误详情 building
        modelCid: sessionStorage.getItem('modelCid'), // building
        modelStatus: 0, // 模型进度 building
        modelProgress: { // 初始化为模型转换，进度为10
            progress: 0,
            phase: sessionStorage.getItem('phase'),
            isFaulted: false,
            isInterrupted: false,
            isVisable: false,
            reMatchProgress: 0,
            reMatchSuc: false, // 没有重新匹配
        },
        calculateType: [], // 模型QSType building
        floorList: [], // 模型楼层列表 building
        selectedFloorList: [], // 选中的模型楼层列表 building
        selectedCategorys: [], // 选中的模型QSType building
        selectedTypes: [], // 选中的模型QSType building
        errorGroup: null, // 错误构件列表选中的错误构件 building
        showError: false, // 错误构件页面 building
        // 钢筋三维 building
        RebarHandle: [],
        xjRebarHandle: [],
        xkRebarHandle: [],
        xkxjRebarHandle: [],
        HelixRebarHandle: 0,
        LineHandle: 0,
        ArcHandle: 0,
        DiscreteRebarHandle: {},
        DiscreteHelixRebarHandle: 0,
        filterGroupCheck: [], // 构件显隐选中名称 building
        switchingModel: { // 切换模型界面 building
            model: true,
        },
        calculateHint: false, // 算量计算提示 building
        rebarSaveVal: [{}], // 算量设置 building
        propertyData: [], // 构件属性值 building
        currentPattern: '3d', // 切换配筋模式
        updateRebar: false, // 刷新钢筋属性
        updateRebarReuse: false, // 钢筋复用后刷新
        updateTree: false, // 刷新构件树
        propertiesHistory,
    },
    getters: {
        buildingId(state) {
            return state.currentBuildingInfo.buildingId;
        },
        buildingName: (state) => state.currentBuildingInfo.name,
        projectReadonly(state, getters, rootState) {
            const projectInfo = rootState.project.currentProjectInfo;
            return !!((state.modelProgress.phase !== '0' && state.modelProgress.phase < 4) || Number(state.progress) || (projectInfo ? projectInfo.readonly : false)); // 计算状态和项目是否只读
        },
    },
    mutations: {
        buildingInfo(state, building) {
            sessionStorage.setItem('buildingInfo', JSON.stringify(building));
            state.currentBuildingInfo = building;
        },
        clearBuildingInfo(state) {
            state.currentBuildingInfo = {};
        },
        modelStatus(state, modelStatus) {
            state.modelStatus = modelStatus;
        },
        modelCid(state, modelCid) {
            state.modelCid = modelCid;
            sessionStorage.setItem('modelCid', modelCid);
        },
        setCurrentPattern(state, id) {
            state.currentPattern = id;
        },
        modelProgress(state, val) {
            state.modelProgress = Object.assign(state.modelProgress, val);
            sessionStorage.setItem('progress', val.progress);
            sessionStorage.setItem('reMatchSuc', val.reMatchSuc);
            sessionStorage.setItem('phase', val.phase);
            sessionStorage.setItem('isFaulted', val.isFaulted);
            sessionStorage.setItem('isInterrupted', val.isInterrupted);
        },
        setProgress(state, data) {
            state.progress = data;
            sessionStorage.setItem('progress', data);
        },
        setProgressSuccess(state, data) {
            state.progressSuccess = data;
            sessionStorage.setItem('progressSuccess', data);
        },
        setCountError(state, data) {
            state.countError = data;
            sessionStorage.setItem('countError', data);
        },
        calculateType(state, calculateType) {
            state.calculateType = calculateType;
        },
        setFloorList(state, floorList) {
            state.floorList = floorList;
        },
        selectedFloorList(state, selectedFloorList) {
            state.selectedFloorList = selectedFloorList;
        },
        selectedCategorys(state, selectedCategorys) {
            state.selectedCategorys = selectedCategorys;
        },
        selectedTypes(state, selectedTypes) {
            state.selectedTypes = selectedTypes;
        },
        errorGroup(state, errorGroup) {
            state.errorGroup = errorGroup;
        },
        showError(state, showError) {
            state.showError = showError;
        },
        RebarHandle(state, RebarHandle) {
            state.RebarHandle = RebarHandle;
        },
        xjRebarHandle(state, xjRebarHandle) {
            state.xjRebarHandle = xjRebarHandle;
        },
        xkRebarHandle(state, xkRebarHandle) {
            state.xkRebarHandle = xkRebarHandle;
        },
        xkxjRebarHandle(state, xkxjRebarHandle) {
            state.xkxjRebarHandle = xkxjRebarHandle;
        },
        HelixRebarHandle(state, HelixRebarHandle) {
            state.HelixRebarHandle = HelixRebarHandle;
        },
        LineHandle(state, LineHandle) {
            state.LineHandle = LineHandle;
        },
        ArcHandle(state, ArcHandle) {
            state.ArcHandle = ArcHandle;
        },
        DiscreteRebarHandle(state, DiscreteRebarHandle) {
            state.DiscreteRebarHandle = DiscreteRebarHandle;
        },
        DiscreteHelixRebarHandle(state, DiscreteHelixRebarHandle) {
            state.DiscreteHelixRebarHandle = DiscreteHelixRebarHandle;
        },
        rebarSaveVal(state, rebarSaveVal) {
            state.rebarSaveVal = rebarSaveVal;
        },
        calculateHint(state, calculateHint) {
            state.calculateHint = calculateHint;
        },
        // business data
        switchingModel(state, switchingModel) {
            state.switchingModel = switchingModel;
        },
        filterGroupCheck(state, filterGroupCheck) {
            state.filterGroupCheck = filterGroupCheck;
        },
        setPropertyData(state, data) {
            state.propertyData = data;
        },
        setUpdateRebar(state) {
            state.updateRebar = true;
            setTimeout(() => {
                state.updateRebar = false;
            }, 20);
        },
        setUpdateRebarReuse(state) {
            state.updateRebarReuse = true;
            setTimeout(() => {
                state.updateRebarReuse = false;
            }, 20);
        },
        setUpdateTree(state) {
            state.updateTree = true;
            setTimeout(() => {
                state.updateTree = false;
            }, 20);
        },
        setPropertyHistory(state, obj) {
            state.propertiesHistory = Object.assign(state.propertiesHistory, obj);
            sessionStorage.setItem('propertiesHistory', JSON.stringify(state.propertiesHistory));
        },
    },
    actions: {
        // 获取工程列表
        async getBuildingList({ state, commit, dispatch }) { // 这个接口的结果只取决于projectId
            const res = await getAllBuildings();
            state.buildingList = res;
            commit('buildingInfo', res[0]);
            dispatch('getAllElementDetails');
        },
        refreshRelatedData({ state, commit, dispatch }) { // 楼层设置修改后前端楼层相关数据需要刷新
            const option = {
                buildingId: state.currentBuildingInfo.buildingId,
            };
            commit('setFloorList', getFloorOptions(option));
            dispatch('modelModule/getAllElementDetails');
        },
    },
};
export default buildingModule;
