import {
    spdcost_http_get, spdcost_http_put, spdcost_http_post,
} from './helper';
import http from '../utils/axiosAsync';

export interface UserInfo {
    userName: string;
    name: string;
    phone: string;
    email: string;
    maxProjectCount: number;
    maxBuildingCount: number;
    maxModelCount: number;
    maxSpaceUsage: number;
    maxTaskCount: number;
    projectCount: number;
    buildingCount: number;
    modelCount: number;
    spaceUsage: number;
    taskCount: number;
}

export const APP_VERSION_PERSONAL = 0; // 个人版
export const APP_VERSION_EDUCATION = 2; // 教育版
export const APP_VERSION_ENTERPRISE = 3; // 企业版
export const APP_VERSION_GOVERNMENT = 1; // 政府版
export const APP_VERSION_PUBLIC_WELFARE = 4; // 公益版

/**
 * 账号版本
 * todo: 不知道起啥名字好
 */
export interface AppVersion {
    id: number;
    name: string;
    appVersion: number;
    appVersionTxt: string;
    externalId: string;
    isExpired: boolean; // 是否过期
}

/**
 * 获取所有账户版本，包括企业版、教育版等
 */
export async function getUserAccounts() {
    const res = await spdcost_http_put('/User/User', {});
    return res as { orgs: AppVersion[] };
}

/**
 * 获取用户个人资料信息
 */
export async function getUserInfo() {
    const res = await spdcost_http_get('/User/User/UserInformation', {}, true, true);
    return res as UserInfo;
}

/**
 * 获取 sso 用户信息，我们只用来获取头像
 * FIXME: 在本地环境下这个接口永远是401，所以这里才判断了 NODE_ENV，还没搞清楚到底是为啥
 */
export async function getUserInfoSSO(cacheFlag = false) {
    const url = process.env.NODE_ENV === 'development' ? 'http://spdcost.dev.spdio.com/spdsso/connect/userinfo' : '/spdsso/connect/userinfo';
    if (cacheFlag) {
        if (sessionStorage.getItem(url)) {
            return JSON.parse(sessionStorage.getItem(url) || '');
        }
        const res = await http({ url, method: 'get' }, cacheFlag);
        sessionStorage.setItem(url, JSON.stringify(res));
        return res as {
            picture: string;
        };
    }
    const res = await http({ url, method: 'get' }, cacheFlag);
    return res as {
        picture: string;
    };
}

/**
 * 编辑用户资料，我们只用来修改用户头像
 */
export async function editUserSSO(params: {
    picture: string;
    name: string;
}) {
    const res = await http({
        url: '/spdsso/identity/api/v1.0/User/profile',
        method: 'post',
        body: { ...params, gender: 0 },
    });
    return res as { success: boolean };
}

/**
 * 编辑用户名
 */
export async function editUserName(params: { name: string }) {
    await spdcost_http_post('/Account/Modify', params);
}
