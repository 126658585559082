import Oidc from 'oidc-client';
import { spdcost_http_get } from './helper';

export interface OidcConfig {
    authHost: string;
    clientId: string;
    fileServerURL: string; // 不属于 OIDC 的信息吧？
    scope: string;
}

/**
 * 缓存 OIDC 的信息
 */
let oidcConfig: OidcConfig;

/**
 * 获取 OIDC 的配置信息
 */
export async function getOidcConfig() {
    if (oidcConfig) return oidcConfig;
    oidcConfig = await spdcost_http_get('/Account/ConfigInfo', {});
    return oidcConfig;
}

/**
 * 刷新 token
 */
export async function refreshToken(params: { refreshToken: string }) {
    const res = await spdcost_http_get(params, '/Account/RefreshToken');
    return res as {
        access_token: string;
        refreshToken: string;
    };
}

/**
 * SSO 配置信息 (在重庆 SSO 系统上弄下来的)
 * todo: 提取到单独文件里面
 */
const ssoConfig = {
    authority: '',
    redirect_uri: `${window.location.origin}/callback.html`,
    resigerUrl: window.location.origin,
    client_id: '',
    scope: '',
    response_type: 'id_token token',
    post_logout_redirect_uri: `${window.location.origin}/login.html`,
    silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
};

/**
 * 登录
 */
export const login = async () => {
    const res = await getOidcConfig();
    const config = {
        authority: res.authHost,
        redirect_uri: ssoConfig.redirect_uri,
        client_id: res.clientId,
        resigerUrl: `${res.authHost}/account/register`,
        scope: res.scope,
        response_type: ssoConfig.response_type,
        post_logout_redirect_uri: ssoConfig.post_logout_redirect_uri,
    };
    const mgr = new Oidc.UserManager(config);
    // mgr.signinRedirect();
    mgr.signinRedirect({ state: window.location.href });
};

/**
 * 注销
 */
export const logout = async () => {
    if (sessionStorage.getItem('embed')) return; // embed 模式是没有注销功能的
    const res = await getOidcConfig();
    const config = {
        authority: res.authHost,
        redirect_uri: ssoConfig.redirect_uri,
        client_id: res.clientId,
        resigerUrl: `${res.authHost}/account/register`,
        scope: res.scope,
        response_type: ssoConfig.response_type,
        post_logout_redirect_uri: ssoConfig.post_logout_redirect_uri,
    };
    const mgr = new Oidc.UserManager(config);
    mgr.signoutRedirect();
    mgr.removeUser();
    // todo: 跟业界实践差异很大
    localStorage.clear();
    sessionStorage.clear();
};

/**
 * 检查是否已经登录
 * todo: 本地开发控制台会报错
 */
export const isLogin = async () => {
    const res = await getOidcConfig();
    const config = {
        authority: res.authHost,
        redirect_uri: ssoConfig.redirect_uri,
        client_id: res.clientId,
        resigerUrl: `${res.authHost}/account/register`,
        scope: res.scope,
        response_type: ssoConfig.response_type,
        post_logout_redirect_uri: ssoConfig.post_logout_redirect_uri,
    };
    const mgr = new Oidc.UserManager(config);
    return await mgr.getUser() !== null;
};
