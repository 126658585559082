const API = {
    /* ----------------------文件上传模块--------------------- */
    uploadFileStatus: '/spdbim/file/api/v1.0/FlowJs/UploadFileStatus', // 查询文件上传的状态
    addFileServer: '/spdbim/file/api/v1.0/File/', // 在fileServer中添加上传成功的文件
    UploadModel: '/spdbim/file/api/v1.0/FlowJs/Upload', // 大文件上传
    deleteModel: '/spdbim/file/api/v1.0/File',
    uploadImage: '/spdbim/file/api/v1.0/File',
    convertModelBySelf: '/spdbim/file/api/v1/File/convertModelBySelf', // 模型转换
    convertProcessBySelf: '/spdbim/file/api/v1/File/convert-processBySelf', // 查询模型转换的进度
    getModelList: '/spdbim/file/api/v1.0/File', // 查询相同modelGroupId下面的模型列表
    modelRelation: '/spdbim/file/api/v1/BIMModel/modelRelation', // 加载模型l
    getFileMes: '/spdbim/file/api/v1.3/File/', // 获取单个文件信息
};

export {
    API,
};
