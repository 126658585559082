import Vue from 'vue';
import Moment from 'moment';

// 处理字符串过长的过滤{{item.name | convertStr(10)}}
Vue.filter('convertStr', (str: string, count: number) => {
    let val = str.replace(/\s+/g, '');
    if (val.length > count) {
        val = `${val.substring(0, count)}...`;
    }
    return val;
});

Vue.filter('money', (v: number) => {
    if (!v) {
        return '';
    }
    if (v >= 10000) {
        return `${(v / 10000).toFixed(2)}亿`;
    }
    return `${v.toFixed(2)}万`;
});

// 时间转换 {{'xxx' | convertTime('yyyy-mm-dd')}}
Vue.filter('convertTime', (data: string, formaStr: string) => Moment(data).format(formaStr));
