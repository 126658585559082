const loadingModule = {
    state: {
        info: {
            text: '',
            show: false,
        },
    },
    mutations: {
        showLoading(state, msg) {
            state.info.show = true;
            state.info.text = msg;
        },
        hideLoading(state) {
            state.info = { show: false, text: '' };
        },
    },
};

export default loadingModule;
