
  import Vue from 'vue';

  export default Vue.extend({
    name: 'BigPicture',
    data() {
      return {
        isOpen: false,
        image: '',
      };
    },
    methods: {
      setImg(img) {
        this.image = img;
        this.isOpen = true;
      },
      hideDialog() {
        this.isOpen = false;
      },
    },
  });
