
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                list: [
                    { content: '切换标准/配筋模式' },
                    { content: '查看修改构件属性信息' },
                    { content: '点击查看构件树' },
                    { content: '构件图形化配筋' },
                    { content: '编辑梁构件原位标注' },
                    { content: '复用已有构件钢筋' },
                    { content: '查看单构件工程量明细' },
                    { content: '查看框选构件的量' },
                ],
            };
        },
    });
