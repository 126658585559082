import { Message } from 'element-ui';
import http from '../utils/axiosAsync';

function renderUrlTemplate(url, params) {
    // 需求：'building/{buildingId}' -> 'building/123'
    // https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/String/replace
    const tmp = url.replace(/\{([^}]+)\}/g, (m, p) => {
        const ret = params[p];
        delete params[p];
        return ret;
    });
    return `/spdcost/api/v1.3${tmp}`;
}

export async function spdcost_http_get(url, opts, cacheFlag = false, isSession = true) {
    if (cacheFlag) { // 需要缓存
        if (isSession) {
            if (sessionStorage.getItem(url)) {
                return JSON.parse(sessionStorage.getItem(url) ?? '');
            }
            const newUrl = renderUrlTemplate(url, opts);
            const res = await http({ url: newUrl, method: 'get', params: opts });
            if (res.success) {
                sessionStorage.setItem(url, JSON.stringify(res.data)); // 添加缓存
                return res.data;
            }
            if (res.message) {
                Message.error(res.message);
                throw Error(res.message);
            }
            throw Error(`服务器内部错误${newUrl}`);
        }
        if (localStorage.getItem(url)) { // 返回缓存的值
            return JSON.parse(localStorage.getItem(url) ?? '');
        }
        const newUrl = renderUrlTemplate(url, opts);
        const res = await http({ url: newUrl, method: 'get', params: opts });
        if (res.success) {
            localStorage.setItem(url, JSON.stringify(res.data)); // 添加缓存
            return res.data;
        }
        if (res.message) {
            Message.error(res.message);
            throw Error(res.message);
        }
        throw Error(`服务器内部错误${newUrl}`);
    } else { // 不缓存
        const newUrl = renderUrlTemplate(url, opts);
        const res = await http({ url: newUrl, method: 'get', params: opts });
        if (res.success) {
            return res.data;
        }
        if (res.message) {
            Message.error(res.message);
            throw Error(res.message);
        }
        throw Error(`服务器内部错误${newUrl}`);
    }
}

export async function spdcost_http_post(url, opts) {
    const newUrl = renderUrlTemplate(url, opts);
    const res = await http({ url: newUrl, method: 'post', body: opts });
    if (res.success) {
        return res.data;
    }
    if (res.message) {
        Message.error(res.message);
        throw Error(res.message);
    }
    throw Error(`服务器内部错误${newUrl}`);
}

export async function spdcost_http_put(url, opts) {
    const newUrl = renderUrlTemplate(url, opts);
    const res = await http({ url: newUrl, method: 'put', body: opts });
    if (res.success) {
        return res.data;
    }
    if (res.message) {
        Message.error(res.message);
        throw Error(res.message);
    }
    throw Error(`服务器内部错误${newUrl}`);
}

export async function spdcost_http_delete(url, opts) {
    const newUrl = renderUrlTemplate(url, opts);
    const res = await http({ url: newUrl, method: 'delete', body: opts });
    if (res.success) {
        return res.data;
    }
    if (res.message) {
        Message.error(res.message);
        throw Error(res.message);
    }
    throw Error(`服务器内部错误${newUrl}`);
}
