import * as Bill from '@/api/bill';
import * as Quantity from '@/api/Quantity';
import * as Project from '@/api/project';
import * as UserInfo from '@/api/UserInfo';
import * as UserAccount from '@/api/UserAccount';
import * as File from '@/api/File';
import * as Message from '@/api/Message';
import * as UserGuides from '@/api/UserGuides';
import * as Address from '@/api/Address';
import * as Const from '@/api/constants';
import * as floor from '@/api/floor';
import * as BeamReinforcement from '@/api/BeamReinforcement';

const api = {
  Bill,
  Quantity,
  Project,
  UserInfo,
  UserAccount,
  File,
  Message,
  UserGuides,
  Address,
  Const,
  floor,
  BeamReinforcement,
};

export default {
  install: (vue) => {
    vue.prototype.$api = api;
  },
};

// 利用模块增强实现代码补全、代码检查等功能
declare module 'vue/types/vue' {
  interface Vue {
    $api: typeof api;
  }
}
