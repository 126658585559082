import { QSCategory } from './types/Quantity';
import {
 spdcost_http_delete, spdcost_http_get, spdcost_http_put,
} from './helper';
import http from '../utils/axiosAsync';
import { PropertyValidator, PropertyValidatorImpl } from './types/PropertyValidator';

let qsCategoriesBuffer: QSCategory[];

/**
 * 获取所有 Category
 */
export async function getAllCategories() {
    if (!qsCategoriesBuffer) qsCategoriesBuffer = await spdcost_http_get('/Quantity/QSType', {}, true);
    return qsCategoriesBuffer;
}

/**
 * 查找这个 Category 下的所有 Type
 */
export async function findTypes(params: { categoryKeyword: string }) {
    const categories = await getAllCategories();
    const category = categories.find((c) => c.keyword === params.categoryKeyword);
    return category ? category.nodes : [];
}

/**
 * 条件查询 Category
 */
export async function findCategories(params: { includeUndefined: boolean }) {
    const res = await spdcost_http_get('/Quantity/QSType', params);
    return res as QSCategory[];
}

/**
 * 判断柱大样是否重名
 */
export async function checkIsExists(params: { buildingId: string; name: string }) {
    const res = await spdcost_http_get('/Quantity/DetailDrawings/IsExists/{name}', params);
    return res as any;
}

/**
 * 保存柱大样
 */
export async function saveDetailDrawings(params: {
    modelElementId: string;
    name: string;
    imageUrl: string;
    rebarData: any;
    buildingId: string;
    isOverrideName: boolean;
    properties: any;
}) {
    const res = await spdcost_http_put('/Quantity/DetailDrawings', params);
    return res as any;
}

/**
 * 柱大样列表
 */
export async function loadDetailDrawings(params: {
    modelElementId: string;
    buildingId: string;
    rebarData: any;
}) {
    const res = await spdcost_http_put('/Quantity/DetailDrawings/loading', params);
    return res as any;
}

/**
 * 删除柱大样
 */
export async function deleteDetailDrawings(params: {
    id: string;
}) {
    const res = await spdcost_http_delete('/Quantity/DetailDrawings/{id}', params);
    return res as any;
}

/**
 * 载入柱大样
 */
export async function reuseRebarDataInDetailDrawing(params: {
    detailDrawingId: string;
    destinationElementIds: string[];
}) {
    const res = await spdcost_http_put('/Quantity/Element/ReuseRebarDataInDetailDrawing', params);
    return res as any;
}

/**
 * 获取验证属性
 */
export async function getRebarValidations(params: {
    qsCategory: number;
}): Promise<PropertyValidator[]> {
    if (localStorage.getItem(`validations${params.qsCategory}`)) {
        const res = JSON.parse(localStorage.getItem(`validations${params.qsCategory}`) ?? '');
        if (res) {
            return (res as PropertyValidator[])?.map((validator) => new PropertyValidatorImpl(validator));
        }
    }
    const res = await spdcost_http_get('/Common/Property/RebarValidations', params);
    localStorage.setItem(`validations${params.qsCategory}`, JSON.stringify(res));
    return (res as PropertyValidator[])?.map((validator) => new PropertyValidatorImpl(validator));
}

/**
 * 框选算量
 */
export async function getBoxChooseDetails(params: {
    buildingId: string;
    elementIds: string[];
    concreteUnit: number;
    concreteBlindingUnit: number;
    rebarUnit: number;
}) {
    const res = await spdcost_http_put('/Quantity/BoxChooseQuantity/BoxChooseDetails', params);
    return res as any;
}

/**
 * 框选算量标号
 */
export async function getConcreteTypeConcreteQuantity(params: {
    buildingId: string;
    elementIds: string[];
    concreteUnit: number;
    concreteBlindingUnit: number;
    rebarUnit: number;
}) {
    const res = await spdcost_http_put('/Quantity/BoxChooseQuantity/ConcreteTypeConcreteQuantity', params);
    return res as any;
}

/**
 * 框选算量导出
 */
export async function putRegionQuantityExport(opt: {
    buildingId: string;
    elementIds: string[];
    concreteUnit: number;
    concreteBlindingUnit: number;
    rebarUnit: number;
}) {
    try {
      const res = await http({
            url: '/spdcost/api/v1.3/Quantity/BoxChooseQuantity/Export', method: 'put', body: opt, responseType: 'arraybuffer',
        });
      return res;
    } catch (err) {
        console.error(err);
        return undefined; // 非业务错误
    }
  }
