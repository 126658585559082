import {
    spdcost_http_get, spdcost_http_put, spdcost_http_post, spdcost_http_delete,
} from './helper';

// 设为首层
export async function asFirstFloor(floorId: string) {
    await spdcost_http_put('/Quantity/Floor/AsFirst', { floorId });
}

// 字典枚举
export async function getDict(code: string) { // 根据不同入参保存返回值
    if (localStorage.getItem(code)) {
        return JSON.parse(localStorage.getItem(code) ?? '');
    }
    const res = await spdcost_http_get('/Foundation/Dict', { code });
    localStorage.setItem(code, JSON.stringify(res));
    return res as any[];
}

// 获取楼层设置
export async function getFloorSet(opts: { floorId: string }) {
    const res = await spdcost_http_get('/Quantity/FloorSetting/{floorId}', opts);
    return res as any[];
}

// 楼层设置恢复默认
export async function FloorSetDefault(params: any) {
    await spdcost_http_put('/Quantity/FloorSetting/Recover', params);
}

// 修改楼层信息
export async function floorInformation(params: any) {
    await spdcost_http_put('/Quantity/Floor', params);
}

// 添加楼层
export async function addFloor(params: {
    floorId: string;
    buildingId: string;
}) {
    await spdcost_http_post('/Quantity/Floor', params);
}

// 删除楼层
export async function removeFloor(params: {
    floorIds: string[];
    buildingId: string;
}) {
    await spdcost_http_delete('/Quantity/Floor', params);
}

// 楼层列表恢复默认
export async function FloorDefault(buildingId: string) {
    await spdcost_http_put('/Quantity/Floor/Restore', { buildingId });
}

// 修改楼层设置
export async function FloorSetInformation(params: any) {
    await spdcost_http_put('/Quantity/FloorSetting', params);
}

// 复制楼层设置
export async function copyFloorSet(params: {
    sourceFloorId: string;
    targetFloorIds: string[];
    buildingId: string;
}) {
    await spdcost_http_put('/Quantity/FloorSetting/Copy', params);
}

// 合并标准层
export async function mergedStandardLayer(params: any) {
    await spdcost_http_put('/Quantity/Floor/Combine', params);
}

export async function mergedFloorr(params: any) {
    await spdcost_http_put('/Quantity/Floor/Amend', params);
}

// 拆分标准层
export async function breakStandardLayer(params: any) {
    await spdcost_http_put('/Quantity/Floor/Split', params);
}

// 获取土建构件
export async function getCivElement(elementId: string) {
    const res = await spdcost_http_get('/Quantity/Element/CivilBills', { elementId });
    return res as any;
}

// 获取钢筋构件
export async function getRebElement(elementId: string) {
    const res = await spdcost_http_get('/Quantity/Element/RebarDatas', { elementId });
    return res as any;
}

// 获取所有构件列表
export async function getAllElementDetails(params: { buildingId: string }) {
    const res = await spdcost_http_get('/Quantity/Element/ElementPropertyList', params);
    return res as {
        totalCount: number;
        modelElementIds: string[];
        viewElementIds: string[];
        floorNumbers: number[];
        qsTypes: number[];
        qsCategories: number[];
    };
}

// 获取当前用户下的所有项目
export async function getProjectList() {
    const res = await spdcost_http_get('/Analysis/Scheme/ProjectByUser', {}, true, true);
    return res as any;
}

// 获取某个项目所有的工程
export async function getBuildingByProject(projectId: string) {
    const res = await spdcost_http_get('/Analysis/Scheme/BuildingByProject', { projectId });
    return res as any;
}

// 获取相交构件
export async function IntersectionArtifacts(opts: { buildingId: string; modelElementId: string }) {
    const res = await spdcost_http_get('/Quantity/Rebar3D/IntersectantElements', opts);
    return res as string[];
}

// 构件工程量对比
export async function getQuantityComparedDetail(params: {
    buildingId: string;
    quantityType: number;
    projectId: string;
}[]) {
    const res = await spdcost_http_post('/Analysis/Scheme/QuantityComparedDetail', params);
    return res as any[];
}
// 方案对比百分比
export async function getpercentage(params: {
    buildingId: string;
    projectId: string;
}[]) {
    const res = await spdcost_http_post('/Analysis/Scheme/EngineeringSumComparison', params);
    return res as {
        typeName: string;
        engineeringTotalCount: number;
    }[][];
}

// 方案对比差异明细
export async function getQuantityDis(opts: any) {
    const res = spdcost_http_post('/Analysis/Scheme/EngineeringQuantityDiscrepancyCompared', opts);
    return res as any;
}

// 方案对比差异明细钢筋
export async function getQuantityReb(params: {
    projectId: string;
    buildingId: string;
}[]) {
    const res = spdcost_http_post('/Analysis/Scheme/BillQuantityRebarDiscrepancyCompared', params);
    return res;
}

// 获取支座信息
export async function getSupportInformation(params: { buildingId: string; modelElementId: string }) {
    const res = spdcost_http_get('/Quantity/BeamReinforcement/SupportInformation', params);
    return res as any;
}

// 修改跨梁配筋信息
export async function chBeamReinforcement(params: {
    id: string;
    fieldName: string;
    value: any;
}) {
    await spdcost_http_put('/Quantity/BeamReinforcement/BeamReinforcementInformation', params);
}

// 获取箍筋样式
export async function getStirrupStyleImages(params: any) {
    const res = await spdcost_http_get('/Quantity/BeamReinforcement/StirrupStyleImages', params);
    return res as any[];
}

// 获取跨梁信息
export async function getCrossbeaminf(params: any) {
    const res = await spdcost_http_post('/Quantity/BeamReinforcement/BeamAcrossInformation', params);
    return res as any[];
}

// 获取楼层构件列表
export async function getfloorartlist(params: any) {
    const res = await spdcost_http_get('/Quantity/FloorModelElements', params);
    return res as any[];
}

// 新增楼层构件
export async function postfloorartlist(params: any) {
    await spdcost_http_post('/Quantity/FloorModelElements', params);
}

// 复制楼层构件
export async function postfloorartcopy(params: any) {
    await spdcost_http_post('/Quantity/FloorModelElements/Copy', params);
}

export async function putfloorartxg(params: any) {
    await spdcost_http_put('/Quantity/FloorModelElements', params);
}

// 获取土建构件清单
export async function getCivilBills(elementId: string) {
    const res = await spdcost_http_get('/Quantity/Element/CivilBills/Custom/{elementId}', { elementId });
    return res as any;
}

// 获取钢筋构件清单
export async function getRebarDatas(elementId: string) {
    const res = await spdcost_http_get('/Quantity/Element/RebarDatas/Custom/{elementId}', { elementId });
    return res as any;
}

// 添加土建构件清单
export async function postCivilBills(params: any) {
    const res = await spdcost_http_post('/Quantity/Element/CivilBills', params);
    return res as {
        id: string;
        value: number;
    };
}

// 修改土建构件清单
export async function putCivilBills(params: any) {
    const res = await spdcost_http_put('/Quantity/Element/CivilBills', params);
    return res as {
        id: string;
        value: number;
    };
}

// 添加钢筋构件清单
export async function postRebarDatas(params: any) {
    const res = await spdcost_http_post('/Quantity/Element/RebarDatas', params);
    return res as {
        id: string;
        weight: number;
        length: number;
    };
}

// 修改钢筋构件清单
export async function putRebarDatas(params: any) {
    const res = await spdcost_http_put('/Quantity/Element/RebarDatas', params);
    return res as {
        id: string;
        weight: number;
        length: number;
    };
}

// 删除土建构件清单
export async function deleteCivilBills(params: string[]) {
    await spdcost_http_delete('/Quantity/Element/CivilBills', params);
}

// 复制
export async function CivilBillsCopy(params: any) {
    await spdcost_http_post('/Quantity/Element/CivilBills/Copy', params);
}

// 复制
export async function RebarDatasCopy(params: any) {
    await spdcost_http_post('/Quantity/Element/RebarDatas/Copy', params);
}

// 删除钢筋构件清单
export async function deleteRebarDatas(params: string[]) {
    await spdcost_http_delete('/Quantity/Element/RebarDatas', params);
}

// 删除构件
export async function deleteElements(params: any) {
    await spdcost_http_delete('/Quantity/FloorModelElements', params);
}

// 修改表格构件
export async function TableProperties(opts: any) {
    await spdcost_http_put('/Quantity/Element/TableProperties', opts);
}

// 三维钢筋
export async function RebarGeometry(params: { modelElementIds: string[] }) {
    const res = await spdcost_http_put('/Quantity/Rebar3D', params);
    return res as any[];
}

// 获取节点图集新
export async function getjointslpicNew(elementId: string) {
    const res = await spdcost_http_get('/Quantity/Element/TableProperties', { elementId });
    return res as any;
}

// 获取二级树
export async function getSecondTree(params: any) {
    const res = await spdcost_http_get('/Quantity/QSType/TableSecondTree', params);
    return res as any[];
}
