import { getCrossbeaminf, chBeamReinforcement } from '@/api/floorSet';
import {
  getRebarValidations,
} from '@/api/Quantity';
import { PropertyValidator } from '@/api/types/PropertyValidator';

interface BeamOption {
  id: string;
  fieldName: string;
  value: any;
}
const modelSitulabel = {
    state: {
      beamOption: null as BeamOption | null,
      uptateSitulabel: false as boolean,
      newBeam: null,
      beamValidators: null as PropertyValidator[] | null,
    },
    mutations: {
      setBeamValidators(state, val) {
        state.beamValidators = val;
      },
      setBeamOption(state, option) {
        state.beamOption = option;
      },
      setNewBeam(state, newBeam) {
        state.newBeam = newBeam;
        setTimeout(() => {
          state.newBeam = null;
        }, 300);
      },
      setUptateSitulabel(state) {
        state.uptateSitulabel = !state.uptateSitulabel;
        setTimeout(() => {
          state.uptateSitulabel = !state.uptateSitulabel;
        }, 300);
      },
    },
    actions: {
      // 新生成跨梁信息
      async postSupports({ state }, option) {
          console.log(state);
        const res = await getCrossbeaminf(option);
        return res;
      },
      // 单个修改跨梁信息
      async putBeamReinforcement({ commit }, option) {
        await chBeamReinforcement(option);
        commit('setBeamOption', option);
      },
      // 跨梁验证信息
      async getBeamValidators({ commit }) {
        const res = await getRebarValidations({ qsCategory: 3 });
        commit('setBeamValidators', res);
      },
    },
};
export default modelSitulabel;
