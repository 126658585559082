import { spdcost_http_get } from './helper';
import { AddressCity, AddressProvince } from './types';

export async function provinces(params: { country: number }) {
    const res = await spdcost_http_get('/Foundation/Address/Provinces', params, true, false);
    return res as AddressProvince[];
}

export async function cities(params: { country?: number; provinceId?: number; province: string }) {
    const res = await spdcost_http_get('/Foundation/Address/Cities', params);
    return res as AddressCity[];
}
